import Modal from './index';
import './ErrorModal.scss';

const ErrorModal = ({ isOpen, closeFn, message, refreshButton, isErrorModal }) => {
    const buttonText = refreshButton ? 'Refresh' : 'Close';
    return (
        <Modal
            isOpen={isOpen}
            closeFn={closeFn}
            title={"Error"}
            body={<div dangerouslySetInnerHTML={{ __html: message }}/>}
            buttons={[{ text: buttonText, isCloseBtn: true }]}
            zIndex={1000}
            className={'error-modal'}
            refreshButton={refreshButton}
            isErrorModal={isErrorModal}
        />
    );
};

export default ErrorModal;
