import './index.scss';

const Button = ({ className, outline, children, ...props}) => (
    <button
        className={`button ${className ? className : ''} ${outline ? 'outline' : ''}`}
        {...props}
    >
        {children}
    </button>
);

export default Button;