import './index.scss';
import Loader from '../Loader'

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            <Loader darkBackground />
        </div>
    );
};

export default LoadingScreen;