import Modal from './index';
import './LoadingModal.scss';
import Loader from '../Loader';

const LoadingModal = ({ isOpen, closeFn, message, isLoadingModal }) => {
    const renderBody = () =>(
        <div className="loading-body">
            <Loader />
            <span>{message}</span>
        </div>
    );
    return (
        <Modal
            isOpen={isOpen}
            closeFn={closeFn}
            body={renderBody()}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            zIndex={900}
            className={"loading-modal"}
            isLoadingModal={isLoadingModal}
        />
    );
};

export default LoadingModal;
