import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import countries from 'i18n-iso-countries';
import './OrderNow.scss';
import Modal from "../global/Modal";
import { camelCaseToTitleCase, camelToKebabCase, validateEmail } from "../../libraries/helpers";
import { newOrder, getOrderCount } from '../../libraries/api';
import Context from "../../context";
import Table from "./Table";
import { getPriceBreakdown } from "../../libraries/priceCalculator";
import Button from '../global/Button/index';
import { TextField, Select, MenuItem, InputLabel, FormControl, Checkbox } from "@mui/material";
import Loader from "../global/Loader";
import BigNumber from "bignumber.js";
import TermsAndConditions from "./TermsAndConditions";

const MIN_QUANTITY = 1;
const MAX_QUANTITY = 20;
const MAX_QUANTITY_EU = 1;
const DEFAULT_COUNTRY = 'GB';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const countryList = countries.getNames("en", { select: "official" });
const COUNTRY_SETTINGS = {
  GB: { // UK
    currencyCodes: [ 'GBP' ],
    maxQuantity: MAX_QUANTITY,
  },
  US: { // USA
    currencyCodes: [ 'USD' ],
    maxQuantity: MAX_QUANTITY,
  },
  AT: { // Austria
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  BE: { // Belgium
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  BG: { // Bulgaria
    currencyCodes: [ 'BGN', 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  HR: { // Croatia
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  // CY: { // Cyprus
  //   currencyCodes: [ 'EUR' ],
  //   maxQuantity: MAX_QUANTITY_EU,
  // },
  CZ: { // Czech
    currencyCodes: [ 'CZK', 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  DK: { // Denmark
    currencyCodes: [ 'DKK', 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  EE: { // Estonia
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  FI: { // Finland
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  FR: { // France
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  DE: { // Germany
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  GR: { // Greece
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  HU: { // Hungary
    currencyCodes: [ 'HUF', 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  IE: { // Ireland
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  IT: { // Italy
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  LV: { // Latvia
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  LT: { // Lithuania
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  LU: { // Luxembourg
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  // MT: { // Malta
  //   currencyCodes: [ 'EUR' ],
  //   maxQuantity: MAX_QUANTITY_EU,
  // },
  NL: { // Netherlands
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  PL: { // Poland
    currencyCodes: [ 'PLN','EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  PT: { // Portugal
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  RO: { // Romania
    currencyCodes: [ 'RON', 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  SK: { // Slovakia
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  SI: { // Slovenia
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  ES: { // Spain
    currencyCodes: [ 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
  SE: { // Sweden
    currencyCodes: [ 'SEK', 'EUR' ],
    maxQuantity: MAX_QUANTITY_EU,
  },
};

const OrderNow = ({
                    showOrderNowModal,
                    setShowOrderNowModal,
                    itinerary,
                    composerData,
                    postsData,
                    options,
                    numPages,
                    paymentOptions,
                    currencies,
                    setNotificationModal,
                    setOrderCount,
                  }) => {
  const {
    openErrorModal,
    openLoadingModal, closeLoadingModal,
  } = useContext(Context);

  const expandMoreButton = useRef(null);

  const [userSettings, setUserSettings] = useState(null);
  const [formFields, setFormFields] = useState(null);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [tableItems, setTableItems] = useState([]);
  const [priceBreakdownData, setPriceBreakdownData] = useState({});
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);

  const { operator: operatorSettings, itinerary: itineraryData, products, prices } = paymentOptions ?? {};

  const getCurrency = useCallback(isoCountryCode => COUNTRY_SETTINGS[isoCountryCode]?.currencyCodes?.find(x => currencies.includes(x)) ?? currencies[0], [currencies]);

  const getDefaultUserSettings = useCallback(() => ({
    quantityRequired: MIN_QUANTITY,
    currencyCode: getCurrency(DEFAULT_COUNTRY),
    isoCountryCode: DEFAULT_COUNTRY,
  }), [getCurrency]);

  useEffect(() => {
    if (!currencies || userSettings) return;
    setUserSettings(() => getDefaultUserSettings());
  }, [currencies, userSettings, getDefaultUserSettings]);

  useEffect(() => {
    if (!userSettings) return;
    const countrySettings = COUNTRY_SETTINGS[userSettings.isoCountryCode];
    const fields = {
      firstName: { type: 'text', value: '', required: true, nameOverride: 'First name' },
      lastName: { type: 'text', value: '', required: true, nameOverride: 'Last name' },
      addressLine1: { type: 'text', value: '', required: true, nameOverride: 'Address (line 1)' },
      addressLine2: { type: 'text', value: '', nameOverride: 'Address (line 2)' },
      addressLine3: { type: 'text', value: '', nameOverride: 'Address (line 3)' },
      town: { type: 'text', value: '', required: true, nameOverride: 'Town/City' },
      county: { type: 'text', value: '', nameOverride: 'County/Region' },
      postCode: {
        type: 'text',
        value: '',
        nameOverride: 'Postcode',
        required: true
      },
      isoCountryCode: {
        type: 'select',
        required: true,
        name: "country",
        value: userSettings.isoCountryCode,
        nameOverride: 'Country',
        options: [
          { value: '', name: 'Select country', disabled: true },
          ...Object.keys(countryList)
              .filter(x => COUNTRY_SETTINGS[x])
              .sort((a, b) => countryList[a].localeCompare(countryList[b]))
              .map(x => ({ value: x, name: countryList[x] }))
        ],
        updateUserSettings: true,
        onChange: false,
      },
      telephone: { type: 'text', value: '', required: true, nameOverride: 'Phone number' },
      email: { type: 'email', value: '', required: true, validator: validateEmail, nameOverride: 'E-mail address' },
      quantity: {
        type: 'number',
        value: handleQuantity(countrySettings, userSettings.quantityRequired),
        required: true,
        name: "quantity",
        min: MIN_QUANTITY,
        max: MAX_QUANTITY,
        updateUserSettings: true,
        onChange: false,
      },
      currency: {
        type: 'select',
        required: true,
        name: "currency",
        value: userSettings.currencyCode,
        // options: currencies.map(x => ({ value: x, name: x })),
        options: [{ value: userSettings.currencyCode, name: userSettings.currencyCode }],
        // updateUserSettings: true,
        onChange: false,
      },
      termsAndConditions: {
        type: 'checkbox',
        value: false,
        required: true,
        name: 'termsAndConditions',
        labelComponent: (
            <>
              I have read and agree to the{'\u00A0'}
              <span
                  className="hyperlink"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTermsAndConditionsModal(true);
                  }}
              >
              Terms and Conditions
            </span>
            </>
        )
      }
    };
    setFormFields(f => {
      const out = { ...fields };
      Object.keys(out).forEach(field => {
        // Ensure userSettings are adhered to.
        if (["quantity", "country", "currency"].includes(out[field].name)) return;
        if (f?.[field]) out[field].value = f[field].value;
      });
      return out;
    });
  }, [currencies, userSettings]);

  useEffect(() => {
    if (!formFields) return;
    setAllowSubmit(() => Object.keys(formFields).every(x => {
      return !formFields[x].required || !!(formFields[x].validator
          ? formFields[x].validator(formFields[x].value)
          : formFields[x].value);
    }));
  }, [formFields]);

  useEffect(() => {
    if (!paymentOptions || !currencies || !userSettings) return;
    if (Object.keys(paymentOptions).length === 0) return;
    let priceBreakdownForTable = [];
    const priceBreakdown = getPriceBreakdown(numPages, userSettings, operatorSettings, {
      ...itineraryData,
      returnDate: itinerary.returnDate
    }, products, prices);

    for (const key in priceBreakdown['breakdown']) {
      if (priceBreakdown['breakdown'][key].quantity === 0) continue;
      if (priceBreakdown['breakdown'][key].totalPrice === 0) continue;
      const out = {
        id: priceBreakdownForTable.length + 1,
        name: priceBreakdown['breakdown'][key].productName,
        quantity: priceBreakdown['breakdown'][key].quantity,
        price: key === 'tax' ? new BigNumber( priceBreakdown['breakdown'][key].totalPrice).toFormat(2) : new BigNumber(priceBreakdown['breakdown'][key].priceDiscounted?.price ?? priceBreakdown['breakdown'][key].price?.price).toFormat(2),
        totalPrice: new BigNumber(priceBreakdown['breakdown'][key].totalPrice).toFormat(2),
      };
      priceBreakdownForTable.push(out);
    }

    if (priceBreakdown.quantityFree !== 0) {
      const freePhotobook = {
        id: priceBreakdownForTable.length + 1,
        name: 'Free Photobook',
        quantity: priceBreakdown.quantityFree,
        price: '0.00',
        totalPrice: '0.00',
      };
      priceBreakdownForTable.unshift(freePhotobook);
    }
    setTableItems(priceBreakdownForTable);
    setPriceBreakdownData(priceBreakdown);
  }, [itinerary.returnDate, itineraryData, numPages, operatorSettings, paymentOptions, prices, products, userSettings, currencies]);

  // hide the expanded price table if user don't have to pay for the photobook
  useEffect(() => {
    if (expandMoreButton.current === null || priceBreakdownData.quantityToPayFor !== 0) return;
    expandMoreButton.current.click();
    setIsTableOpen(false)
  }, [priceBreakdownData.quantityToPayFor]);

  const handleQuantity = (countrySettings, newQuantity) => {
    if (!newQuantity) return MIN_QUANTITY;
    if (!Number.isInteger(newQuantity)) newQuantity = parseInt(newQuantity);
    if (newQuantity < MIN_QUANTITY) return MIN_QUANTITY;
    if (newQuantity > countrySettings.maxQuantity) return countrySettings.maxQuantity;
    return newQuantity;
  };

  const updateUserSettings = useCallback(e => {
    if (!userSettings) return;
    if (!['quantity', 'country'].includes(e.target.name)) return;
    if (e.target.name === 'country') {
      const countrySettings = COUNTRY_SETTINGS[e.target.value];
      setUserSettings(u => ({
        isoCountryCode: e.target.value,
        currencyCode: getCurrency(e.target.value),
        quantityRequired: handleQuantity(countrySettings, userSettings.quantityRequired),
      }));
    } else if (e.target.name === 'quantity') {
      setUserSettings(u => {
        return ({
          ...u,
          quantityRequired: handleQuantity(COUNTRY_SETTINGS[u.isoCountryCode], e.target.value),
        })
      });
    }
  }, [userSettings, getCurrency]);

  const renderInput = (x) => {
    const defaultOpts = {
      required: formFields[x].required,
      name: formFields[x].name,
      value: formFields[x].value,
      onChange: e => {
        if (formFields[x].onChange !== false) {
          setFormFields(f => ({
            ...f,
            [x]: {
              ...f[x],
              value: formFields[x].type === 'checkbox' ? !formFields[x].value : e.target.value
            }
          }))
        }
        if (formFields[x].updateUserSettings) updateUserSettings(e);
      }
    };

    const singleSelectInput = (formFields[x].type === 'select' && formFields[x].options.length === 1);

    return (
      <div
        className="input-container"
      >
        {
          formFields[x].type === 'select' ? (
              <FormControl className='order-now-input'>
                {/* If only one item in select dropdown, just show text */}
                {/* Also use input type=hidden so the value is still successfully submitted */}
                {
                  singleSelectInput ? (
                      <>
                        <div className="order-now-text">{formFields[x].options[0].name ?? formFields[x].options[0].value}</div>
                        <input type="hidden" value={formFields[x].options[0].value} />
                      </>
                  ) : (
                      <>
                        <InputLabel variant="outlined" size='small'>
                          {formFields[x].nameOverride}
                        </InputLabel>
                        <Select
                            {...defaultOpts}
                            disabled={formFields[x].disabled}
                            value={formFields[x].value}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            label={formFields[x].nameOverride}
                            size='small'
                            id={camelToKebabCase(x)}
                        >
                          {
                            formFields[x].options.map((x, i) => (
                                <MenuItem
                                    key={i}
                                    value={x.value}
                                    disabled={x.disabled}
                                >
                                  {x.name}
                                </MenuItem>
                            ))
                          }
                        </Select>
                      </>
                  )
                }
              </FormControl>
          ) : formFields[x].type === 'checkbox' ? (
              <>
                <Checkbox
                    {...defaultOpts}
                    checked={!!defaultOpts.value}
                />
                <div className="checkbox-label" onClick={defaultOpts.onChange}>
                  {formFields[x].labelComponent}
                </div>
              </>
          ) : (
              <TextField
                  {...defaultOpts}
                  type={formFields[x].type}
                  className='order-now-input'
                  InputProps={{ inputProps: { min: formFields[x].min, max: formFields[x].max }, className: 'order-now-input' }}
                  placeholder={formFields[x].nameOverride}
                  size='small'
                  label={`${formFields[x].type === 'number' ? '' : formFields[x].nameOverride || camelCaseToTitleCase(x)}`}
                  id={camelToKebabCase(x)}
              />
          )
        }
      </div>
    );
  }

  const renderBody = () => {
    if (!formFields) return <div>Loading...</div>;
    return (
      <div className="order-now-body">
        <div className="form">
          <div className="half-row">
            {renderInput('firstName')}
            {renderInput('lastName')}
          </div>
          <div className="row">
            {renderInput('addressLine1')}
            {renderInput('addressLine2')}
            {renderInput('addressLine3')}
          </div>
          <div className="half-row">
            {renderInput('town')}
            {renderInput('postCode')}
          </div>
          <div className="half-row">
            {renderInput('isoCountryCode')}
          </div>
          <div className="half-row">
            {renderInput('telephone')}
            {renderInput('email')}
          </div>
          <div className="row">
            {renderInput('termsAndConditions')}
          </div>
        </div>
        <div className="bottom-row">
          <div className={`table ${isTableOpen ? 'active' : ''}`}>
            <Table
              tableItems={tableItems}
            />
          </div>
          <div className="left">
            <span>Copies:</span>
            {renderInput('quantity')}
          </div>
          <div className="right">
            {
              Object.keys(priceBreakdownData).length === 0 ?
              <div className="loading-body">
                <Loader />
              </div>
              :
                <div className={`right-price ${priceBreakdownData.quantityToPayFor !== 0 ? '' : 'hidden'}`}>
                  <span>Total:</span>
                  {renderInput('currency')}
                  <div className="price">{new BigNumber(priceBreakdownData.totalPrice).toFormat(2)}</div>
                  <img className={`expand ${isTableOpen ? 'active' : ''}`} src="/icons/expand_more.svg" alt=""
                       onClick={() => {
                         setIsTableOpen(!isTableOpen)
                       }}
                       ref={expandMoreButton}
                  />
                </div>
            }
            <div className="btn">
              <Button
                className={'button modal-button modal-button-modal-default'}
                onClick={() => {
                  onSubmit();
                }}
                disabled={!allowSubmit}
              > {priceBreakdownData.quantityToPayFor !== 0 ? 'Go to payment' : 'Confirm'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onSubmit = async () => {
    openLoadingModal('You order is processing. Please do not refresh the browser.');

    const fulfilmentInfo = {};
    Object.keys(formFields).forEach(x => fulfilmentInfo[x] = formFields[x].value);

    const clientPaymentData = {
      numPages: numPages,
      userSettings: userSettings,
      priceBreakdown: priceBreakdownData
    };
    const { res, data, error } = await newOrder(itinerary, postsData, composerData, options, fulfilmentInfo, clientPaymentData);
    if (!res?.ok || error) {
      closeLoadingModal();
      openErrorModal((data?.error || error?.message || `An error occurred: ${res.status}. Your order did not go through. Please try again. `), true);
      return;
    }

    if (data.isSettled) {
      const orderCountResponse = await getOrderCount(itinerary.id);
      if (orderCountResponse.res.ok && !orderCountResponse.error) {
        setOrderCount(orderCount => ({ ...orderCount, isLoading: false, data: orderCountResponse.data }))
      }
      setNotificationModal({ isActive: true, orderRef: data.orderRef });
      closeModal();
      closeLoadingModal();
    } else {
      window.location.href = data.checkoutSession.url;
    }
  };

  const closeModal = () => {
    setShowOrderNowModal(false);
    setIsTableOpen(false);
    setFormFields(f => {
      const defaultUserSettings = getDefaultUserSettings();
      const out = { ...f };
      Object.keys(out).forEach(x => {
        if (out[x].name === "quantity") out[x].value = defaultUserSettings.quantityRequired;
        else if (out[x].name === "country") out[x].value = defaultUserSettings.isoCountryCode;
        else if (out[x].name === "currency") out[x].value = defaultUserSettings.currencyCode;
        else out[x].value = '';
      });
      //setUserSettings field to default values
      setUserSettings(defaultUserSettings);
      return out;
    });
  };

  const renderTermsAndConditionsModal = useCallback(() => {
    const closeTermsAndConditionsModal = () => {
      setShowTermsAndConditionsModal(false);
    }

    const renderBody = () => {
      return (
        <div className='terms-and-conditions-body'>
          <TermsAndConditions />
        </div>
      )
    }

    return (<Modal
        isOpen={showTermsAndConditionsModal}
        closeFn={closeTermsAndConditionsModal}
        title="Terms and Conditions"
        body={renderBody()}
        className={"modal-default"}
        closeButton={true}
      />
    );
  }, [showTermsAndConditionsModal]);

  return (
    <>
    <Modal
      isOpen={showOrderNowModal === true}
      closeFn={closeModal}
      title="Delivery information"
      body={renderBody()}
      className={"modal-default"}
      closeButton={true}
    />
      {renderTermsAndConditionsModal()}
    </>
  );
};

export default OrderNow;
