import React from 'react';
import ReactModal from 'react-modal';
import Button from '../Button';
import './index.scss';

ReactModal.setAppElement('#root');

const Modal = ({ isOpen, closeFn, title, body, buttons, zIndex, className, ...props }) => {
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: zIndex ?? 'auto'
        },
        content: {
            top: '30%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -30%)',
            minWidth: `${(props.isErrorModal || props.isLoadingModal) ? '50%' : '55%'}`,
            padding: `${props.closeButton ? '0' : '20px'}`,
            overflow: 'hidden',

        },
    };

    const generateClassName = base => `${base} ${className ? `${base}-${className}` : ''}`;

    return (
        <ReactModal
            isOpen={isOpen}
            style={customStyles}
            onRequestClose={closeFn}
            shouldCloseOnOverlayClick={!props.refreshButton}
            shouldCloseOnEsc={!props.refreshButton}
            {...props}
        >
            { props.closeButton && <img className="close" src="/icons/exit.svg" alt="" onClick={closeFn} /> }
            { title && <h2 className={generateClassName('modal-title')}>{title}</h2> }
            { body && <div className={generateClassName('modal-body')}>{body}</div> }
            {
                buttons && (
                    <div className={generateClassName('buttons-container')}>
                        {
                            buttons.map(({ onClick, isCloseBtn, text, ...props }, i) => (
                                <Button
                                    className={generateClassName('modal-button')}
                                    key={i}
                                    onClick={() => {
                                        onClick && onClick();
                                        isCloseBtn && closeFn();
                                    }}
                                    { ...props }
                                >
                                    {text}
                                </Button>
                            ))
                        }
                    </div>
                )
            }
        </ReactModal>
    );
};

export default Modal;
