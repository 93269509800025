import './TermsAndConditions.scss';

const Section = ({ header, content }) => {
    if (content && !Array.isArray(content)) content = [content];
    return (
        <div className="section">
            {header && <h2 dangerouslySetInnerHTML={{__html: header }}/>}
            {content && content.map((x, i) => <p key={i} dangerouslySetInnerHTML={{__html: x }}/>)}
        </div>
    );
};

const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions">
            <Section
                content="By using our service, (herein referred to as &quotService&quot) you are deemed to have read and accepted the terms and conditions set forth, hence forming a legal binding agreement between Vamoos Limited., (herein referred to as &quotVamoos&quot, &quotwe&quot, &quotus&quot, &quotour&quot and their affiliates) and yourself pursuant to which you agree to comply with the Terms and Conditions, and use this website only in a manner consistent with Terms and Conditions. Should you not agree with any part of this Terms and Conditions, you are advised not to use this Service."
            />
            <Section
                header="1. Legal Age Requirement"
                content="There is no legal minimum age requirement to access and browse this website; however, we do advise that anyone below the age of 18 should obtain parental consent in using our services, and for the submission of credit card information when placing an order. We strongly recommend that parents and guardians supervise their children’s use of the Website."
            />
            <Section
                header="2. Software License, Copyright & Ownership of Content"
                content="All Software material, information and content furnished by Vamoos are protected by copyright and other applicable and relevant laws. You agree that you will not reproduce, modify, decompile, disassemble or derive the source code for the Software, publish any performance or benchmark test or analyses relating to the Software of the use thereof. You will have rights to use this Software under the condition that you do not change the materials or remove any copyright or other proprietary rights notices in the materials. You may not distribute or copy such material without the written permission of the copyright owner. By downloading the Software, you hereby understand and accept that while Vamoos takes serious measures to prevent security issues, we do not guarantee the Software is free from viruses, errors or any contaminating or destructive codes, such as worms or Trojan horses."
            />
            <Section
                header="3. About your Content"
                content={[
                    "You may design your photo book through our mobile and web applications. Context of design includes without limitation, photographic images and texts, files, works of authorship, illustration and drawings as part of your use of the Service.",
                    "Under these Terms and Conditions, you warrant that you will not use any of the services for any fraudulent or illegal purpose, and you may NOT place any of the following material using the Service:",
                    "a) Any material that could infringe on the rights of publicity, privacy or copyrights without the approval and consent of the owner of these rights;",
                    "b) Any material that could infringe any materials that is unlawful, obscene, pornographic, indecent, defamatory, offensive, abusive, threatening, or otherwise inappropriate and harmful to children in any way, or could give rise to any civil or criminal liability under applicable law;",
                    "c) Any material that contains viruses, worms, corrupt files, Trojan horse or other sort of corrupted codes.",
                    "d) Any material that is offensive or promote racism, bigotry or hatred against any group or individual",
                    "While Vamoos does not and cannot review all submissions, and is not responsible for their content, Vamoos reserves the right to move, refuse to print and delete submissions that, in its sole discretion, is believed to be as described in paragraphs (a) through (d) above, or otherwise unacceptable. You acknowledge and agree that any submission may be published, copied, customised or removed for the purposes of delivering the Services. In addition, you undertake that you will be liable for any action taken against Vamoos in relation to such activities and will fully indemnify Vamoos against all actions, claims, demands or costs which may arise as a result.",
                    "You should always retain your own copies of all files uploaded or otherwise supplied to us. Vamoos has no ownership in any of your book content."
                ]}
            />
            <Section
                header="5. Order Policy"
                content="Receipt of an electronic order or other form of order confirmation by no means signify Vamoos’ acceptance of your order, nor does it constitute confirmation of our offer to sell. Vamoos reserves the exclusive right at any time after receipt of your order, to accept or decline your order for any reason. Vamoos also reserves the right at any time after receipt of your order and without prior notice to you, to supply less than the quantity you order for any time. Your order is deemed accepted by Vamoos upon our delivery of products that you have ordered. We may require additional verifications or information before accepting any order."
            />
            <Section
                header="6. Fee and Payment Terms"
                content={[
                    "All prices are in their respective local currencies and payment must accompany each order. There is no charge for browsing or accessing the Site. Prices, products and services offered are subject to change without prior notice from time to time at our discretion upon posting by Vamoos.",
                    "You hereby agree to pay all fees and charges incurred in connection with your orders which include without limitation taxes, foreign transaction fee, duty imports, value-added taxes and customs clearance, if any. Payment terms will be via online credit/debit card transactions. We reserve the right to refer to your credit card provider. Vamoos will not be held liable for any losses you may suffer as a result of unauthorized use of your credit card by any other party. An electronic sales order confirmation will be sent to your email address once your order has been captured into our system.",
                    "Failure to submit a valid payment may result in refusal to process the requested service(s).",
                    "In cases of disputes, you must alert us by writing to photobook@vamoos.com within 7 days after receiving your credit card statement, if you dispute any of our charges on that statement. If Vamoos does not receive payment from your credit card issuer, you are obliged to pay all amounts due upon demand by Vamoos.",
                ]}
            />
            <Section
                header="7. Delivery of Products"
                content="All products or prints will be delivered to the delivery address supplied by you during the order process. Time for delivery is an estimate only and it is not a condition of this agreement. Delayed delivery is not a basis for cancellation of the order or a request for compensation. Orders may be refused if we believe that there is any infringement to this Terms and Conditions, and entirely at the discretion of Vamoos."
            />
            <Section
                header="8. Force Majeure"
                content="A party will not be liable for non-performance or delay in performance caused by any extraordinary event or circumstance beyond the control of such party including, without limitation, wars, strikes, riots, hostilities, revolutions, national emergency, explosion, civil commotion, embargo, fire, flood, earthquake, volcanic eruption, and force of nature or any &quotacts of God&quot."
            />
            <Section
                header="9. Satisfaction Guarantee & Product Warranty"
                content={[
                    "Vamoos is proud of the outstanding quality and craftsmanship of our products. Refunds and cancellation of order is generally not provided except for cases of process or product malfunction (i.e. workmanship defect, book arrives damaged, product do not match ordered items).",
                    "As the photobooks are assembled by hand, the end result may differ from our website images. Vamoos also reserves the right to change or alter any production methods without prior notice. These may or may not result in a slight difference in the final outlook or slight colour shifts, while maintaining the same quality and standards.",
                    "<span class=\"italics\">IMPORTANT: We do NOT accept any amendments or changes to be made to the photobook once the order has been confirmed. Therefore, it is solely the responsibility of the customer to check thoroughly before ordering their project.</span>",
                    "Vamoos is not responsible for customer-generated mistakes, errors or defects including spelling, typographical or grammar errors, poor image quality derived from low-resolution images, order quantity or other ordering errors. Please be sure to preview your books carefully before placing your order. Images and text will be printed as they appear in the Preview. We do not proofread for, or correct, any such customer generated mistakes.",
                    "To file a complaint, kindly contact our customer service by writing to us together with picture of problem/areas of dissatisfaction. We will assess the condition of the item(s), and at our sole discretion to either repair or replace the product(s) that does not meet the warranty or condition. All complaints on defective product(s) must be submitted within 30 days upon receiving your product(s).",
                ]}
            />
            <Section
                header="10. Acceptance of Agreement"
                content="YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO ALL ITS TERMS AND CONDITIONS. Vamoos reserves the right, at our sole discretion, to update or make modifications to this Terms and Conditions from time to time without prior notice."
            />
            <Section
                header="11. Copyright Information"
                content="Copyright © 2022 - 2023. All Rights Reserved. The logos and trademarks displayed on the Website and/or products are the sole property of Vamoos. Usage of these marks is strictly prohibited. Vamoos also reserves the right to print their logo on products produced."
            />
        </div>
    );
};

export default TermsAndConditions;