import { getItinerary, validateItinerary } from "./api";
import config from '../config';

export const login = async ({ userId, passcode, method }) => {
    let out;
    try {
        const itineraryResponse = await getItinerary(userId, passcode);
        if (!itineraryResponse.res?.ok || itineraryResponse.data?.error) {
            const message = itineraryResponse.res?.status === 404
                ? 'Itinerary not found'
                : itineraryResponse.data?.error ?? 'There was an error getting itinerary information. Please contact support.'
            throw new Error(message);
        }
        if (method === 'auto' && !itineraryResponse.data.photobookLink?.toLowerCase()?.startsWith(config.photobookLinkHost + `/autologin/${userId.toLowerCase()}/${passcode.toLowerCase()}`)) {
            throw new Error('Auto login not allowed for this operator');
        }
        const validationResponse = await validateItinerary({
            id: itineraryResponse.data.id,
            operatorCode: itineraryResponse.data.operatorInfo.code,
            type: itineraryResponse.data.type,
        });
        if (!validationResponse.res?.ok || validationResponse.data?.error) {
            throw new Error(validationResponse.data?.error ?? 'There was an error validating the itinerary details. Please contact support.');
        }
        out = { validationResponse: validationResponse.data, itinerary: itineraryResponse.data };
        delete out.itinerary.pois;
        delete out.itinerary.tracks;
        delete out.itinerary.routes;
    } catch (e) {
        out = { error: e.message };
        console.error(e);
    }
    return out;
};