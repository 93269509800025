import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { getPosts, getComposerData, getHtml, updateSession, getOrderCount, getLatestOrderOptions, getPaymentOptions } from "../../libraries/api";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Context from '../../context';
import './index.scss';
import PreviewContent from "./PreviewContent";
import Header from "../global/Header";
import PreviewOptions from "./PreviewOptions";
import OrderNow from './OrderNow';
import Tips from './Tips';
import Modal from "../global/Modal";
import LoadingScreen from '../global/LoadingScreen';

const PAGE_LIMIT = 120;

const Preview = () => {
  const mounted = useRef(false);

  const [options, setOptions] = useState({
    excludeSeparatorPages: false,
  });
  const [composerData, setComposerData] = useState({ isLoading: false, data: undefined });
  const [postsData, setPostsData] = useState({ isLoading: false, data: null });
  const [previewHtml, setPreviewHtml] = useState({ isLoading: false, data: null });
  const [sessionData, setSessionData] = useState({ isLoading: false, data: null });
  const [latestOrderOptions, setLatestOrderOptions] = useState({ isLoading: false, data: null });
  const [showOrderNowModal, setShowOrderNowModal] = useState(null);
  const [notificationModal, setNotificationModal] = useState({ isActive: false, orderRef: null });
  const [checkoutSessionStatus, setCheckoutSessionStatus] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [paymentOptions, setPaymentOptions] = useState({ isLoading: false, data: null });
  const [currencies, setCurrencies] = useState(null);
  const [orderCount, setOrderCount] = useState({ isLoading: false, data: null });
  const [numPagesRaw, setNumPagesRaw] = useState(null);
  const [pageCountModal, setPageCountModal] = useState(null);

  const {
    loginDetails,
    itinerary,
    errorModalIsOpen, openErrorModal,
    loadingModalIsOpen, openLoadingModal, closeLoadingModal,
  } = useContext(Context);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const status = searchParams.get('s');
  const orderRef = searchParams.get('ref');

  useEffect(() => {
    // Used to determine whether component is mounted (for async request state updates)
    // Used by useCallback fns
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const renderNotificationModal = useCallback(() => {
    const closeNotificationModal = () => {
      setNotificationModal({ isActive: false, orderRef: null })
      setCheckoutSessionStatus(null)
    }

    const renderBody = () => {
      return (
        <div className='notification-body'>
          <div>Thank you for your purchase, your order is now being processed. See email for confirmation.</div>
          <div>If you did not receive an email please send a message to <a className="hyperlink" href="mailto:photobook@vamoos.com">photobook@vamoos.com</a> quoting order reference: {notificationModal.orderRef}</div>
        </div>
      )
    }

    return (<Modal
        isOpen={notificationModal.isActive}
        closeFn={closeNotificationModal}
        title="Thank you!"
        body={renderBody()}
        className={"modal-default"}
        closeButton={true}
      />
    );
  }, [notificationModal]);

  const renderPageCountModal = useCallback(() => {
    const closePageCountModal = () => {
      setPageCountModal(false);
    }

    const renderBody = () => {
      return (
        <div className='page-count-body'>
          We currently support {PAGE_LIMIT} pages in a photobook. Yours is {numPagesRaw} which exceeds the limit. The last pages will be cut off.
        </div>
      )
    }

    return (<Modal
        isOpen={pageCountModal === null && Number.isInteger(numPagesRaw) && numPagesRaw > PAGE_LIMIT}
        closeFn={closePageCountModal}
        title="Warning"
        body={renderBody()}
        className={"modal-default"}
        closeButton={true}
      />
    );
  }, [numPagesRaw, pageCountModal]);

  useEffect(() => {
    if (showOrderNowModal) return;
    const somethingLoading = !!(composerData.isLoading || postsData.isLoading || previewHtml.isLoading || sessionData.isLoading);

    if (!errorModalIsOpen && !loadingModalIsOpen && somethingLoading) {
      openLoadingModal(`${checkoutSessionStatus === null ? 'Generating preview' : checkoutSessionStatus === 'success' ? 'Payment processing...' : 'Payment was canceled'}`);
      return;
    }

    if (loadingModalIsOpen && !somethingLoading) {
      closeLoadingModal();
    }

    if (checkoutSessionStatus === 'success' && !somethingLoading && !loadingModalIsOpen && sessionData.data !== null) {
      setNotificationModal({ isActive: true, orderRef: sessionData.data.orderRef });
    }
  }, [showOrderNowModal, closeLoadingModal, composerData.isLoading, errorModalIsOpen, loadingModalIsOpen, openLoadingModal, postsData.isLoading, previewHtml.isLoading, sessionData.isLoading, sessionData.data, checkoutSessionStatus]);


  useEffect(() => {
    if (!sessionId) return;
    setCheckoutSessionStatus(status);
    navigate('/preview')
  }, [navigate, sessionId, status])

  const makeAsyncRequest = useCallback(async (updateStateFn, requestPromise, errorMsg = undefined) => {
    if (!mounted.current) return;
    updateStateFn(state => ({ ...state, isLoading: true, data: null }));
    const { data, res, error } = await requestPromise;
    if (!mounted.current) return;
    if (!res?.ok || error) {
      updateStateFn(state => ({ ...state, isLoading: false, data: null }));
      if (errorMsg) openErrorModal(errorMsg);
      console.error({ data, res, error });
      return;
    }
    updateStateFn(state => ({ ...state, isLoading: false, data }));
  }, [openErrorModal]);

  useEffect(() => {
    if (!itinerary) return;
    makeAsyncRequest(setLatestOrderOptions, getLatestOrderOptions(itinerary.id));
  }, [itinerary, makeAsyncRequest]);

  useEffect(() => {
    if (!loginDetails.bundleId) return;
    makeAsyncRequest(setComposerData, getComposerData(loginDetails.bundleId), 'Error getting branding information. Please contact support.')
  }, [loginDetails.bundleId, makeAsyncRequest]);

  useEffect(() => {
    if (!loginDetails.userId || !loginDetails.passcode) return;
    makeAsyncRequest(setPostsData, getPosts(loginDetails.userId, loginDetails.passcode), 'Error getting posts data. Please contact support.');
  }, [loginDetails.passcode, loginDetails.userId, makeAsyncRequest]);

  useEffect(() => {
    if (sessionId || !itinerary || !latestOrderOptions.data || !postsData.data || (loginDetails.bundleId && !composerData.data)) return;
    makeAsyncRequest(setPreviewHtml, getHtml(itinerary, postsData.data, composerData.data, latestOrderOptions.data?.latestOrderOptions), 'Error generating preview. Please contact support.');
  }, [composerData.data, itinerary, loginDetails.bundleId, makeAsyncRequest, postsData.data, latestOrderOptions.data, sessionId]);

  useEffect(() => {
    if (!status || !sessionId) return;
    makeAsyncRequest(setSessionData, updateSession(sessionId, status), 'There was an error submitting your order. Please send a message to <a class="hyperlink" href="mailto:photobook@vamoos.com">photobook@vamoos.com</a> quoting order reference: ' + orderRef);
  }, [makeAsyncRequest, sessionId, status, orderRef]);

  useEffect(() => {
    if (!itinerary || previewHtml?.isLoading || sessionData?.isLoading) return;
    makeAsyncRequest(setOrderCount, getOrderCount(itinerary?.id));
  }, [previewHtml?.isLoading, sessionData?.isLoading, itinerary, makeAsyncRequest]);

  useEffect(() => {
    if (!itinerary) return;
    if (showOrderNowModal === false) return;
    makeAsyncRequest(setPaymentOptions, getPaymentOptions(itinerary.operatorInfo.id, itinerary.id));
  }, [showOrderNowModal, itinerary, makeAsyncRequest]);

  useEffect(() => {
    if (!paymentOptions.data) return;
    let currencyCode = new Set();
    paymentOptions.data.prices
        .sort((a, b) => (a.id - b.id))
        .forEach(element => {
          currencyCode.add(element.currencyCode);
        });
    setCurrencies(Array.from(currencyCode));
  }, [paymentOptions]);

  useEffect(() => {
    if (!previewHtml.data) return;
    if (!latestOrderOptions.data) return;

    /*
    Cases:
      server excludes by default: hide checkbox
      latest order excludes: display checkbox selected
      less than threshold: display checkbox selected
    Priority:
      server hides by default -> there is a latest order -> less than threshold
    */
    setOptions(opts => {
      const threshold = 10;
      return {
        ...opts,
        excludeSeparatorPages: previewHtml.data.text.excludeAllSeparatorPagesByDefault ? false :
        latestOrderOptions.data.latestOrderOptions?.excludeSeparatorPages !== undefined ? latestOrderOptions.data.latestOrderOptions.excludeSeparatorPages :
        previewHtml.data.text.numPosts < threshold,
      };
    });
  }, [previewHtml.data, latestOrderOptions.data]);

  const somethingLoading = !!(composerData.isLoading || postsData.isLoading || previewHtml.isLoading || sessionData.isLoading || latestOrderOptions.isLoading);
  const error = !somethingLoading && (!itinerary || !postsData.data || (loginDetails.bundleId && !composerData.data) || !previewHtml.data);

  return (
    <div className="preview-screen">
      <Header/>
      <div className="main-content-container">
        {renderNotificationModal()}
        {renderPageCountModal()}
        {
          error
            ? <div className="error">Couldn't generate preview. Please contact support</div>
            : (
              <>
                <PreviewContent
                  html={previewHtml.data?.text?.html}
                  somethingLoading={somethingLoading}
                  options={options}
                  setNumPages={setNumPages}
                  setNumPagesRaw={setNumPagesRaw}
                  pageLimit={PAGE_LIMIT}
                />
                <PreviewOptions
                  somethingLoading={somethingLoading}
                  hideExcludeSeparatorsCheckbox={previewHtml.data?.text?.excludeAllSeparatorPagesByDefault}
                  options={options}
                  setOptions={setOptions}
                  setShowOrderNowModal={setShowOrderNowModal}
                  setPaymentOptions={setPaymentOptions}
                  setCurrencies={setCurrencies}
                  numPagesRaw={numPagesRaw}
                  orderCount={orderCount.data?.orderCount}
                />
                <Tips/>
              </>
            )
        }
      </div>
      <OrderNow
        showOrderNowModal={showOrderNowModal}
        setShowOrderNowModal={setShowOrderNowModal}
        itinerary={itinerary}
        composerData={composerData.data}
        postsData={postsData.data}
        options={options}
        numPages={numPages}
        paymentOptions={paymentOptions.data}
        currencies={currencies}
        setNotificationModal={setNotificationModal}
        setOrderCount={setOrderCount}
      />
    </div>
  );
};

export default Preview;
