import config from '../config/index';

export const request = async (url, options = {}, useVamoosHeaders) => {
    if (useVamoosHeaders) {
        options.headers = {
            "User-Agent": 'Photobook',
            "x-vamoos-user-info": JSON.stringify({"version_id":30201,"platform":"photobook"}),
            ...options?.headers
        };
    }

    const out = {};
    try {
        out.res = await fetch(url, options);

        if (out.res.headers.get("content-type").includes("application/json")) {
            out.data = await out.res.json();
        } else if (out.res.headers.get("content-type").includes("image")) {
            out.data = await out.res.arrayBuffer();
        } else if (out.res.headers.get("content-type").includes("text")) {
            out.data = await out.res.text();
        } else {
            out.data = null;
        }

    } catch (e) {
        out.error = e;
    }
    return out;
};

export const getItinerary = async (userId, passcode) => {
    return request(`${config.vamoosBackendHost}/api/itineraries/${userId}-${passcode}`, undefined, true);
};

export const getPosts = async (userId, passcode) => {
    return request(`${config.vamoosBackendHost}/api/itineraries/${userId}-${passcode}/journal`, undefined, true);
};

export const getComposerData = async bundleId => {
    return request(`${config.composerHost}/public_bundles/${bundleId}`, undefined, false);
};

export const validateItinerary = async ({ id, operatorCode, type }) => {
    return request(`${config.backendHost}/validate?id=${id}&operatorCode=${operatorCode}&type=${type}`, undefined, false);
};

export const getHtml = async (itinerary, posts, composer, options = {}) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itinerary, posts, composer, options }),
    };
    return request(`${config.backendHost}/getHtml`, requestOptions, false);
};

export const newOrder = async (itinerary, posts, composer, options, fulfilmentInfo, clientPaymentData) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itinerary, posts, composer, options, fulfilmentInfo, clientPaymentData }),
    };
    return request(`${config.backendHost}/newOrder`, requestOptions, false);
};

export const getPaymentOptions = async (operatorId, itineraryId) => {
    return request(`${config.backendHost}/pricing?operatorId=${operatorId}&itineraryId=${itineraryId}`, undefined, false);
};

export const updateSession = async (sessionId, status) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId, status }),
    };
    return request(`${config.backendHost}/updateSession`, requestOptions, false);
};

export const getOrderCount = async (itineraryId) => {
    return request(`${config.backendHost}/orderCount/${itineraryId}`, undefined, false);
};

export const getLatestOrderOptions = async itineraryId => {
    return request(`${config.backendHost}/latestOrderOptions/${itineraryId}`, undefined, false);
};
