import Button from "../global/Button";
import './PreviewOptions.scss';


const PreviewOptions = ({ hideExcludeSeparatorsCheckbox, somethingLoading, options, setOptions, setShowOrderNowModal, numPagesRaw, orderCount }) => {

  const onOrderNowClick = async () => {
    setShowOrderNowModal(true);
  }

  const renderOptionButtons = () => {
    let infoString = '';
    if (Number.isInteger(numPagesRaw)) {
      infoString += `Total pages: ${numPagesRaw}`;
    }
    if (Number.isInteger(orderCount) && orderCount > 0) {
      if (infoString) infoString += ' / '
      infoString += `Orders made so far: ${orderCount}`;
    }


    return (
      <div className="options-buttons-container">
        <div className="info">
          { infoString }
        </div>
        {
          hideExcludeSeparatorsCheckbox
            ? null
            : (
              <div className="exclude-separator-pages">
                <input
                  type="checkbox"
                  id="exclude-separator-pages"
                  checked={options.excludeSeparatorPages}
                  onChange={e => setOptions(opts => ({ ...opts, excludeSeparatorPages: e.target.checked }))}
                  disabled={somethingLoading}
                />
                <label htmlFor="exclude-separator-pages">Exclude separator pages</label>
              </div>
            )
        }
      </div>
    );
  };

  const renderOrderNow = () => {
    return (
      <div className="order-now-container">
        <Button
          className="order-now-button"
          onClick={onOrderNowClick}
          disabled={somethingLoading}
        >
          Order now
        </Button>
      </div>
    );
  };

  return (
    <div className="preview-options">
      {renderOptionButtons()}
      {renderOrderNow()}
    </div>
  );
};

export default PreviewOptions;
