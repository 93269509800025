const BigNumber = require('bignumber.js');
BigNumber.set({ DECIMAL_PLACES: 2 });

const findProduct = (products, name, isoCountryCode, discounted = false) => {
    const matchingProducts = products.filter(x => {
        if (x.name !== name) return false;
        return (!!x.isDiscounted) === discounted;
    });
    let out = matchingProducts.find(x => x.isoCountryCode === isoCountryCode);
    if (!out) out = matchingProducts.find(x => !x.isoCountryCode);
    if (!out && !discounted) throw new Error("Product not found: " + name);
    return out;
};

const findPrice = (product, prices, currencyCode, operatorId) => {
    if (!product) return null;
    const matchingPrices = prices.filter(x => x.currencyCode === currencyCode && x.productId === product.id);
    let out = matchingPrices.find(x => x.operatorId === operatorId);
    if (!out) out = matchingPrices.find(x => !x.operatorId);
    if (!out) throw new Error(`Price not found: productId: ${product.id}, operatorId: ${operatorId}, currencyCode: ${currencyCode}`);
    return out;
};

const findProductAndPrice = (applyDiscount, products, prices, operatorId, userSettings, name) => {
    const product = findProduct(products, name, userSettings.isoCountryCode);
    const productDiscounted = applyDiscount ? findProduct(products, name, userSettings.isoCountryCode, true) : null;
    return {
        product,
        price: findPrice(product, prices, userSettings.currencyCode, operatorId),
        productDiscounted,
        priceDiscounted: productDiscounted ? findPrice(productDiscounted, prices, userSettings.currencyCode, operatorId) : null,
    }
};

const getTax = (value, isoCountryCode) => {
    const iossTax = {
        AT: 10, // Austria
        BE: 6, // Belgium
        BG: 9, // Bulgaria
        HR: 5, // Croatia
        CY: 5, // Cyprus
        CZ: 10, // Czech
        DK: 25, // Denmark
        EE: 9, // Estonia
        FI: 10, // Finland
        FR: 5.5, // France
        DE: 7, // Germany
        GR: 6, // Greece
        HU: 5, // Hungary
        IE: 0, // Ireland
        IT: 4, // Italy
        LV: 12, // Latvia
        LT: 9, // Lithuania
        LU: 3, // Luxembourg
        MT: 5, // Malta
        NL: 9, // Netherlands
        PL: 5, // Poland
        PT: 6, // Portugal
        RO: 5, // Romania
        SK: 10, // Slovakia
        SI: 5, // Slovenia
        ES: 4, // Spain
        SE: 6, // Sweden
    };
    const multiplier = iossTax[isoCountryCode];
    if (!multiplier) return new BigNumber(0);
    return value.multipliedBy(multiplier).dividedBy(100).decimalPlaces(2);
};

/**
 *
 * @param {number} numPages
 * @param {{ quantityRequired: number, currencyCode: string, isoCountryCode: string }} userSettings
 * @param {{ id: number, discountDays: number, freeOrders: number, [key: string]: any }} operatorSettings
 * @param {{ id: number, orderCount: number, [key: string]: any }} itineraryData
 * @param {Array.<{ id: number, name: string, isoCountryCode: string, isDiscounted: boolean, [key: string]: any }>} products
 * @param {Array.<{ id: number, productId: number, currencyCode: string, operatorId: number, price: number, [key: string]: any }>} prices
 * @returns {{quantityToPayFor: number, quantityFree: number, totalPrice: number, breakdown?: {}}}
 */
export const getPriceBreakdown = (numPages, userSettings, operatorSettings, itineraryData, products, prices ) => {
    const numFreeOrders = Math.max(operatorSettings.freeOrders - itineraryData.orderCount, 0);
    const quantityToPayFor = Math.max(userSettings.quantityRequired - numFreeOrders, 0);
    const quantityFree = userSettings.quantityRequired - quantityToPayFor;
    const out = {
        quantityToPayFor,
        quantityFree,
        totalPrice: new BigNumber(0),
        totalPricePaidByOperator: new BigNumber(0),
        value: new BigNumber(0),
    };

    let applyDiscount = false;
    if (operatorSettings.discountDays > 0) {
        const returnDate = new Date(itineraryData.returnDate * 1000);
        returnDate.setUTCHours(0, 0, 0, 0);
        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);
        const daysSinceEnd = (today - returnDate) / (1000 * 60 * 60 * 24);
        applyDiscount = daysSinceEnd <= operatorSettings.discountDays;
    }

    const basePhotobookPages = 20;
    const breakdown = {
        photobook: {
            productName: 'Photobook',
            quantity: quantityToPayFor,
            quantityPaidByOperator: quantityFree,
        },
        extraPages: {
            productName: 'Extra page',
            quantity: Math.max(quantityToPayFor * (numPages - basePhotobookPages), 0),
            quantityPaidByOperator: Math.max(quantityFree * (numPages - basePhotobookPages), 0),
        },
        tax: {
            productName: 'VAT',
        },
        shipping: {
            productName: 'Shipping',
            // If entire order is free, operator pays, otherwise customer
            quantity: quantityToPayFor ? 1 : 0,
            quantityPaidByOperator: quantityToPayFor ? 0 : 1,
        },
    };

    const defaultArgs = [applyDiscount, products, prices, operatorSettings.id, userSettings];
    let totalPriceWithoutShipping = new BigNumber(0);
    let totalPriceOperatorWithoutShipping = new BigNumber(0);
    for (const key in breakdown) {
        if (key === 'tax') continue; // Handled separately after
        breakdown[key] = {
            ...findProductAndPrice(...defaultArgs, breakdown[key].productName),
            ...breakdown[key]
        };
        const price = new BigNumber(breakdown[key].priceDiscounted?.price ?? breakdown[key].price.price).decimalPlaces(2);
        const quantity = new BigNumber(breakdown[key].quantity);
        const totalPrice = price.multipliedBy(quantity).decimalPlaces(2);

        let totalPricePaidByOperator = new BigNumber(0);
        if (breakdown[key].quantityPaidByOperator !== undefined) {
            const pricePaidByOperator = new BigNumber(breakdown[key].price.price).decimalPlaces(2);
            const quantityPaidByOperator = new BigNumber(breakdown[key].quantityPaidByOperator);
            totalPricePaidByOperator = pricePaidByOperator.multipliedBy(quantityPaidByOperator).decimalPlaces(2);
        }
        if (key !== 'shipping') {
            totalPriceWithoutShipping = totalPriceWithoutShipping.plus(totalPrice);
            const operatorDiscount = new BigNumber(0.85); // Operator pays 15% less
            totalPricePaidByOperator = totalPricePaidByOperator.multipliedBy(operatorDiscount).decimalPlaces(2);
            totalPriceOperatorWithoutShipping = totalPriceOperatorWithoutShipping.plus(totalPricePaidByOperator);
        }
        out.totalPrice = out.totalPrice.plus(totalPrice);
        out.totalPricePaidByOperator = out.totalPricePaidByOperator.plus(totalPricePaidByOperator);
        if (['photobook', 'extraPages'].includes(key)) out.value = out.value.plus(totalPrice).plus(totalPricePaidByOperator);
        breakdown[key].totalPrice = totalPrice.toNumber();
        breakdown[key].totalPricePaidByOperator = totalPricePaidByOperator.toNumber();
    }

    const tax = getTax(totalPriceWithoutShipping, userSettings.isoCountryCode);
    if (tax) {
        breakdown.tax.totalPrice = tax.toNumber();
        breakdown.tax.quantity = 1;
        out.totalPrice = out.totalPrice.plus(tax);
    }
    const operatorTax = getTax(totalPriceOperatorWithoutShipping, userSettings.isoCountryCode);
    if (operatorTax) {
        breakdown.tax.totalPricePaidByOperator = operatorTax.toNumber();
        breakdown.tax.quantityPaidByOperator = 0;
        out.totalPricePaidByOperator = out.totalPricePaidByOperator.plus(operatorTax);
    }
    out.totalPrice = out.totalPrice.toNumber();
    out.totalPricePaidByOperator = out.totalPricePaidByOperator.toNumber();
    out.value = out.value.toNumber();

    return { ...out, breakdown };
};

// module.exports = { getPriceBreakdown };