import { DataGrid } from '@mui/x-data-grid';
import { useContext } from 'react';
import Context from "../../context";

const Table = ({ tableItems }) => {
  const { viewMode  } = useContext(Context);

  const baseColumnOptions = {
    sortable: false,
    pinnable: false,
    hideable: false,
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      ...baseColumnOptions,
      flex: 2,
    },
    {
      field: 'quantity',
      headerName: `${viewMode === 'desktop' ? 'Quantity' : 'Qty'}`,
      ...baseColumnOptions,
      flex: 1,
      align:'center',
      headerAlign: 'center',
    },
    {
      field: 'price',
      headerName: `${viewMode === 'desktop' ? 'Unit price' : 'Price'}`,
      ...baseColumnOptions,
      flex: 1,
      align:'right',
      headerAlign: 'right',
    },
    {
      field: 'totalPrice',
      headerName: 'Total',
      flex: 1,
      ...baseColumnOptions,
      align:'right',
      headerAlign: 'right',
            valueGetter: ({ row }) => {
        return row.totalPrice;
      },
    },
  ];


  const items = Object.values(tableItems);

  const rows = [
    ...items
  ];


  return (
    <div style={{ width: '90%', filter: 'blur(0)' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            sx={{ boxShadow: 0, border: 0, fontFamily: 'inherit'}}
            disableColumnFilter
            disableColumnMenu
            hideFooter
            autoHeight
            rowHeight={50}
            columnHeaderHeight={55}
          />

</div>
  );
};

export default Table;
