const appConfig = () => {
    const env = process.env.REACT_APP_ENV ?? process.env.NODE_ENV ?? "development";
    if (env === 'development') {
        return {
            vamoosBackendHost: 'https://live.vamoos.com/v3',
            // vamoosBackendHost: 'https://uat.vamoos.com',
            // vamoosBackendHost: 'http://localhost:3000',
            composerHost: 'https://live.vamoos.com/composer',
             backendHost: 'http://localhost:3003',
            //backendHost: 'http://192.168.1.197:3003',
            webVamoosHost: 'http://localhost:3333',
            // photobookLinkHost: 'http://localhost:3001',
            photobookLinkHost: 'https://photobook.vamoos.com',
        }
    }

    if (env === 'uat') {
        return {
            vamoosBackendHost: 'https://uat.vamoos.com',
            composerHost: 'https://live.vamoos.com/composer',
            backendHost: 'https://photobook-uat.vamoos.com/api',
            webVamoosHost: 'https://web-uat.vamoos.com',
            photobookLinkHost: 'https://photobook-uat.vamoos.com',
        }
    }

    if (env === 'testing') {
        return {
            vamoosBackendHost: 'https://live.vamoos.com/v3',
            composerHost: 'https://live.vamoos.com/composer',
            backendHost: 'https://photobook-test.vamoos.com/api',
            webVamoosHost: 'https://web.vamoos.com',
            photobookLinkHost: 'https://photobook.vamoos.com',
        }
    }

    if (env === 'beta') {
        return {
            vamoosBackendHost: 'https://live.vamoos.com/v3',
            composerHost: 'https://live.vamoos.com/composer',
            backendHost: 'https://photobook-beta.vamoos.com/api',
            webVamoosHost: 'https://web.vamoos.com',
            photobookLinkHost: 'https://photobook.vamoos.com',
        }
    }

    if (env === 'production') {
        return {
            vamoosBackendHost: 'https://live.vamoos.com/v3',
            composerHost: 'https://live.vamoos.com/composer',
            backendHost: 'https://photobook.vamoos.com/api',
            webVamoosHost: 'https://web.vamoos.com',
            photobookLinkHost: 'https://photobook.vamoos.com',
        }
    }
};

export default appConfig();
