import {useEffect, useState, useContext} from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './index.scss';
import Button from '../global/Button';
import TextInput from '../global/Inputs/Text';
import { login } from '../../libraries/login';
import Context from '../../context';

const Login = ({ method }) => {
    const { userId, passcode } = useParams();
    const [loginFormVals, setLoginFormVals] = useState({ userId: userId ?? '', passcode: passcode ?? '' });
    const [isLoggingIn, setIsLoggingIn] = useState(method === 'auto');

    const {
        localStorageLoginKey,
        setItinerary,
        setLoginDetails,
        openErrorModal,
        openLoadingModal, closeLoadingModal,
        logout,
    } = useContext(Context);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (method === 'auto') {
            logout(); // In case already logged in
            if (!userId || !passcode) navigate('/login');
        }
    }, [logout, navigate, passcode, method, userId]);

    useEffect(() => {
        isLoggingIn ? openLoadingModal('Checking login details') : closeLoadingModal();
    }, [closeLoadingModal, isLoggingIn, openLoadingModal]);

    useEffect(() => {
        if (!isLoggingIn) return;
        let isActive = true;
        const asyncLogin = async () => {
            const res = await login({ userId: loginFormVals.userId, passcode: loginFormVals.passcode, method });
            if (!isActive) return;
            if (res.error) {
                openErrorModal(res.error);
                localStorage.removeItem(localStorageLoginKey);
                navigate('/login');
                return;
            }
            setItinerary(() => res.itinerary);
            const newLoginDetails = {
                ...res.validationResponse,
                userId: loginFormVals.userId,
                passcode: loginFormVals.passcode,
                method,
            };
            setLoginDetails(() => newLoginDetails);
            localStorage.setItem(localStorageLoginKey, JSON.stringify(newLoginDetails));
            navigate('/preview');
        };

        asyncLogin().then(() => isActive && setIsLoggingIn(false));
        return () => {
            isActive = false;
        };
    }, [isLoggingIn, localStorageLoginKey, loginFormVals.passcode, loginFormVals.userId, openErrorModal, setItinerary, setLoginDetails, method, navigate, location?.pathname]);

    const disableLogin = !loginFormVals.userId || !loginFormVals.passcode;

    const renderMainContent = () => {
        return (
            <>
                <TextInput
                    className="login-input"
                    type="text"
                    value={loginFormVals.userId}
                    placeholder="User ID"
                    autoFocus={true}
                    onChange={e => setLoginFormVals(v => ({ ...v, userId: e.target.value }))}
                    onKeyUp={e => {
                        if (disableLogin) return;
                        if (e.code === 'Enter') setIsLoggingIn(true);
                    }}
                />
                <TextInput
                    className="login-input"
                    type="password"
                    value={loginFormVals.passcode}
                    placeholder="Passcode"
                    onChange={e => setLoginFormVals(v => ({ ...v, passcode: e.target.value }))}
                    onKeyUp={e => {
                        if (disableLogin) return;
                        if (e.code === 'Enter') setIsLoggingIn(true);
                    }}
                />
                <Button
                    onClick={() => setIsLoggingIn(true)}
                    disabled={disableLogin}
                >
                    Log in
                </Button>
            </>
        );
    };


    return (
        <div className="login">
            <header>
                <img src="/images/vamoos_logo.png" alt="" />
                <h1 className="title">Photobook</h1>
            </header>
            <main>
                { (method === 'manual') && renderMainContent() }
            </main>
        </div>
    );
};

export default Login;