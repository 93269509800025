import Button from "../Button";
import { useContext } from 'react';
import Context from '../../../context';
import { useNavigate } from 'react-router-dom';
import './index.scss';

const Header = () => {
    const navigate = useNavigate();
    const { logout } = useContext(Context);

    return (
        <div className="header">
            <div className="left">
                <img src="/icons/vamoosIcon.png" alt="" />
                <h2>Photobook</h2>
            </div>
            <Button
                className="right logout"
                onClick={() => {
                    logout();
                    navigate('/login');
                }}
            >
                Log out
            </Button>
        </div>
    );
};

export default Header;

