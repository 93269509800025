import './Text.scss';

const TextInput = ({ className, ...props }) => {
    return (
        <input
            className={`text-input ${className ? className : ''}`}
            { ...props }
        />
    );
};

export default TextInput;