import Loader from "../global/Loader";
import { useEffect, useCallback } from "react";
import './PreviewContent.scss';

const PreviewContent = ({ html, somethingLoading, options, setNumPages, setNumPagesRaw, pageLimit }) => {

    const handleExcludeSeparatorsToggle = useCallback(() => {
        // Note this fn is used in 2 places: 1 - in the iframe load listener, and 2 in the use effect below it.
        // This is because it needs to initially run once as soon as the iframe has loaded.
        // After that it can run as per the dependencies of the useeffect.

        if (!html) return;
        // Handles 'options.excluseSeparatorPages' on the frontend side.
        const preview = document.querySelector('iframe#preview');
        if (!preview) return;
        const previewBody = preview.contentWindow.document.body;
        if (!previewBody) return;
        const pagesContainer = previewBody?.querySelector('.pages');
        if (!pagesContainer) return;
        const pages = Array.from(pagesContainer?.querySelectorAll('.layout'));

        if (options.excludeSeparatorPages) {
            // Put separators at the end and hide them
            const separators = [];
            let nonSeparatorCount = 0;
            for (let i = 0; i < pages.length; i++) {
                const page = pages[i];
                if (page.classList.contains('separator')) {
                    page.classList.add('hidden');
                    separators.push(...pages.splice(pages.indexOf(page), 1));
                    i--;
                } else if (['layout', 'single', 'text-page'].every(x => page.classList.contains(x))) {
                    // Cut off pages exceeding the limit
                    if (nonSeparatorCount < pageLimit) page.classList.remove('hidden');
                    else (page.classList.add('hidden'));
                    nonSeparatorCount++;
                }
            }
            pagesContainer.replaceChildren(...pages, ...separators);

            if (!pagesContainer.classList.contains('exclude-separators')) {
                pagesContainer.classList.add('exclude-separators');
            }
        } else {
            // Sort by position attribute to put them back into orig order
            pages.sort((a, b) => {
                const aPos = parseInt(a.getAttribute('position'));
                const bPos = parseInt(b.getAttribute('position'));
                return aPos - bPos;
            });
            let i = 0;
            for (const page of pages) {
                // Cut off pages exceeding the limit
                if (['layout', 'single', 'text-page'].every(x => page.classList.contains(x))) {
                    if (i < pageLimit) page.classList.remove('hidden');
                    else (page.classList.add('hidden'));
                    i++;
                }
            }
            pagesContainer.replaceChildren(...pages);

            if (pagesContainer.classList.contains('exclude-separators')) {
                pagesContainer.classList.remove('exclude-separators');
            }
        }

        // Count num pages for print and overall
        let totalPageCount = 0;
        let printPageCount = 0;
        for (const p of pagesContainer?.querySelectorAll('.layout.text-page:not(.screen-only)')) {

            const s = getComputedStyle(p);
            // Want to include blank pages added to make up the min 20 pages
            const blankPageClassList = 'layout single blank-page text-page print-only';
            const isBlankPage = p.getAttribute('class') === blankPageClassList;
            if (isBlankPage || (s.display !== 'none' && s.visibility !== 'hidden')) printPageCount++;
            if (!isBlankPage) {
                if (
                    (options.excludeSeparatorPages && !p.classList.contains('separator')) ||
                    !options.excludeSeparatorPages
                ) {
                    totalPageCount++;
                }
            }
        }
        printPageCount = Math.max(printPageCount, 20); // Ensure min 20 pages
        pagesContainer.setAttribute('printPageCount', printPageCount);
        pagesContainer.setAttribute('totalPageCount', totalPageCount);
        setNumPages(printPageCount);
        setNumPagesRaw(totalPageCount);
    }, [html, options.excludeSeparatorPages, setNumPages, setNumPagesRaw, pageLimit]);

    useEffect(() => {
        // Handles zoom for preview, so it fits in the container.
        if (!html) return;
        const previewContainer = document.querySelector('#preview-container');
        const preview = document.querySelector('iframe#preview');
        if (!previewContainer || !preview) return;

        const iframeLoad = () => {
            // preview.contentWindow.document.body.style.zoom = (previewContainer.clientHeight / preview.contentWindow.document.body.scrollHeight).toString();
            // Note: 13 is the padding X applied to preview (iframe). 816 is the height of the ejs template in pixels (210mm + (3mm * 2)) (bleed = 3mm)
            const scale = ((previewContainer.clientHeight - (13*2)) / 816).toString();
            // preview.contentWindow.document.body.style.zoom = scale.toString();

            if (navigator.userAgent.includes('Safari') && navigator.vendor.startsWith('Apple')) {
                previewContainer.style.overflow = 'hidden';
                preview.style.transform = `scale(${scale})`;
                preview.style.transformOrigin = '0 0';
                preview.style.height = `${100/scale}%`;
                preview.style.width = `${100/scale}%`;
            } else {
                preview.contentWindow.document.body.style.transform = `scale(${scale})`;
                preview.contentWindow.document.body.style.transformOrigin = '0 0';
            }

            // preview.style.height = (preview.clientHeight * scale).toString() + 'px';
            const loader = document.getElementById('preview-content-loader');
            loader.classList.add('hidden');
            preview.classList.remove('hidden');
            const optionsButtons = document.querySelectorAll('.options-buttons-container > *');
            for (const btn of optionsButtons) {
                btn.style.visibility = 'visible';
            }

            // Bit of a hack to allow scrolling on Safari.
            // Safari only scrolls after resize.
            // preview.style.width = (preview.scrollWidth + 1) + 'px';

            handleExcludeSeparatorsToggle();

        };

        preview.addEventListener('load', iframeLoad);
        return () => {
            preview.removeEventListener('load', iframeLoad);
        }
    }, [handleExcludeSeparatorsToggle, html]);

    useEffect(() => {
        handleExcludeSeparatorsToggle();
    }, [handleExcludeSeparatorsToggle]);

    return (
        <div id="preview-container">
            {/*{*/}
            {/*    (somethingLoading || !iframeLoadedRef.current)*/}
            {/*        ? <Loader />*/}
            {/*        // : <div id="preview" dangerouslySetInnerHTML={{__html: html}} />*/}
            {/*        : <iframe id="preview" title="preview" srcDoc={html} />*/}
            {/*}*/}
            <Loader id="preview-content-loader" />
            {
                !somethingLoading && <iframe
                    id="preview"
                    title="preview"
                    className="hidden"
                    srcDoc={html}
                />
            }
        </div>
    );
};

export default PreviewContent;
