import { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.scss';
import Context from './context';
import { login } from './libraries/login';

import Login from './components/Login';
import Preview from './components/Preview';
import Book from './components/Book';

import LoadingScreen from './components/global/LoadingScreen';
import ErrorModal from './components/global/Modal/ErrorModal';
import LoadingModal from "./components/global/Modal/LoadingModal";

const localStorageLoginKey = 'pbLogin';

const App = () => {
  const [loginDetails, setLoginDetails] = useState(null);
  const [itinerary, setItinerary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorModalOptions, setErrorModalOptions] = useState({
    isOpen: false,
    message: '',
    refreshButton: false
  });
  const [loadingModalOptions, setLoadingModalOptions] = useState({
    isOpen: false,
    message: ''
  });
  const [viewMode, setViewMode] = useState('desktop');

  const openErrorModal = useCallback((message, refreshButton) => setErrorModalOptions(() => ({
    isOpen: true,
    message,
    refreshButton
  })), []);

  const closeErrorModal = useCallback(() => {
    if (errorModalOptions.refreshButton) {
      window.location.reload(false);
    }

    setErrorModalOptions(() => ({ isOpen: false, message: '', refreshButton: false }));
  }, [errorModalOptions.refreshButton]);

  const openLoadingModal = useCallback((message) => setLoadingModalOptions(opts => ({
    ...opts,
    isOpen: true,
    message
  })), []);
  const closeLoadingModal = useCallback(() => setLoadingModalOptions(opts => ({
    ...opts,
    isOpen: false,
    message: ''
  })), []);

  const logout = () => {
    localStorage.removeItem(localStorageLoginKey);
    setLoginDetails(null);
    setItinerary(null);
  };

  useEffect(() => {
    const MIN_DESKTOP_SIZE = 1025;
    if (window.innerWidth < MIN_DESKTOP_SIZE) setViewMode('mobile'); // Initial setup

    let currentWindowWidth = window.innerWidth;
    const onWindowResize = () => {
      if (window.innerWidth < MIN_DESKTOP_SIZE && currentWindowWidth >= MIN_DESKTOP_SIZE) {
        setViewMode('mobile');
      }
      if (window.innerWidth >= MIN_DESKTOP_SIZE && currentWindowWidth < MIN_DESKTOP_SIZE) {
        setViewMode('desktop');
      }
      currentWindowWidth = window.innerWidth;
    };

    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, []);


  useEffect(() => {
    let existingLogin = localStorage.getItem(localStorageLoginKey);
    if (!existingLogin) {
      setIsLoading(false);
      return;
    }

    let isActive = true;
    existingLogin = JSON.parse(existingLogin);

    const asyncLogin = async () => {
      const res = await login({
        userId: existingLogin.userId,
        passcode: existingLogin.passcode,
        method: existingLogin.method
      });
      if (res.error) {
        localStorage.removeItem(localStorageLoginKey);
        return;
      }
      if (!isActive) return;
      setItinerary(() => res.itinerary);
      const newLoginDetails = {
        ...res.validationResponse,
        userId: existingLogin.userId,
        passcode: existingLogin.passcode,
        method: existingLogin.method,
      };
      setLoginDetails(() => newLoginDetails);
      localStorage.setItem(localStorageLoginKey, JSON.stringify(newLoginDetails));
    };

    asyncLogin().then(() => isActive && setIsLoading(false));

    return () => {
      isActive = false;
    };
  }, []);

  const routes = () => {
    const selectedRoute = loginDetails ?
      { path: '/preview', component: <Preview/> } :
      { path: '/login', component: <Login method="manual" /> };

    return (
      <Routes>
        {/*<Route path="/" element={<div>HelloWorld!</div>} />*/}
        <Route path="book" element={<Book/>}/>
        <Route path="autologin/:userId/:passcode" element={<Login method="auto" />} />
        <Route path={selectedRoute.path} element={selectedRoute.component}/>
        <Route path="*" element={<Navigate to={selectedRoute.path}/>}/>
      </Routes>
    );
  };

  return (
    <Context.Provider
      value={{
        loginDetails, setLoginDetails,
        itinerary, setItinerary,
        localStorageLoginKey,
        errorModalIsOpen: errorModalOptions.isOpen, openErrorModal, closeErrorModal,
        loadingModalIsOpen: loadingModalOptions.isOpen, openLoadingModal, closeLoadingModal,
        viewMode, setViewMode,
        logout,
      }}
    >
      <Router>
        <div className="App">
          {
            isLoading ? <LoadingScreen/> :
              routes()
          }
          <ErrorModal
            isOpen={errorModalOptions.isOpen}
            closeFn={closeErrorModal}
            message={errorModalOptions.message}
            refreshButton={errorModalOptions.refreshButton}
            isErrorModal={true}
          />
          <LoadingModal
            isOpen={!errorModalOptions.isOpen && loadingModalOptions.isOpen}
            closeFn={closeLoadingModal}
            message={loadingModalOptions.message}
            isLoadingModal={true}
          />

        </div>
      </Router>
    </Context.Provider>
  );
}

export default App;
