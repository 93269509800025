import './Tips.scss';
import config from '../../config';

const Tips = () => {
    return (
        <div className="tips">
            <h1>Tips for creating your Photobook!</h1>
            <p>Any changes to your Photobook must be made on the app or by logging into <a className="hyperlink" href={config.webVamoosHost} target="_blank" rel="noreferrer">web.vamoos.com</a>. Refresh this page to preview your changes.</p>

            <p>To make sure captions appear with the photo rather than on another page, consider reducing the amount of text.</p>

            <p>Separator pages always appear on the right. If you have a blank page before a separator, you can add an extra photo into that day at no extra cost.</p>

            <p>There is a 120 page limit. If photos or captions are not appearing at the end of your Photobook, you may need to delete some earlier posts.</p>

            <p>If unexpected changes are appearing, make sure no one else in your travel party is editing the posts.</p>

            <p>To view the cost of your Photobook click on the Order button – you can always go back and make changes before confirming.</p>

            <p>The presentation of the Photobook above is a preview. There may be slight differences in the final printed version.</p>

            <p>Currently the Vamoos Photobook is available for delivery to the following countries: </p>
            <ul>
                <li>Austria</li>
                <li>Belgium</li>
                <li>Bulgaria</li>
                <li>Croatia</li>
                {/*<li>Cyprus</li>*/}
                <li>Czech Republic</li>
                <li>Denmark</li>
                <li>Estonia</li>
                <li>Finland</li>
                <li>France</li>
                <li>Germany</li>
                <li>Greece</li>
                <li>Hungary</li>
                <li>Ireland</li>
                <li>Italy</li>
                <li>Latvia</li>
                <li>Lithuania</li>
                <li>Luxembourg</li>
                {/*<li>Malta</li>*/}
                <li>Netherlands</li>
                <li>Poland</li>
                <li>Portugal</li>
                <li>Romania</li>
                <li>Slovakia</li>
                <li>Slovenia</li>
                <li>Spain</li>
                <li>Sweden</li>
                <li>UK</li>
                <li>USA</li>
            </ul>
        </div>
    );
};

export default Tips;